import Link from "next/link";
import Image from "next/image";
import localFont from "next/font/local";

const calSans = localFont({
  src: "../../fonts/CalSans-SemiBold.woff",
  variable: "--font-calSans",
});

const hero = ({ content }) => {
  return (
    <section className="relative pb-10 pt-20 md:pt-32 h-1527">
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 block dark:hidden h-full">
        <Image
          width={1920}
          height={900}
          src="/images/gradient.jpg"
          alt="gradient"
          className="h-full w-full"
          priority
        />
      </picture>
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
        <Image
          width={1920}
          height={900}
          src="/images/gradient_dark.jpg"
          alt="gradient dark"
          className="h-full w-full"
          priority
        />
      </picture>

      <div className="container h-full mx-auto">
        <div className="grid h-full items-center gap-4 md:grid-cols-12">
          <div className="col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start md:py-20 xl:col-span-4">
            <h1
              className={`${calSans.variable} font-calSans text-jacarta-700 font-bold mb-6 text-center text-5xl dark:text-white md:text-left lg:text-6xl xl:text-7xl`}
            >
              {content?.heroTitle}
            </h1>
            <p className="dark:text-jacarta-200 mb-8 text-center text-lg md:text-left">
              {content?.heroDescription}
            </p>
            <div className="flex space-x-4">
              <Link
                href="/contact"
                className="bg-accent shadow-accent-volume hover:bg-accent-dark w-37 rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
              >
                Contact Us
              </Link>
              <Link
                href="/about"
                className="text-accent shadow-white-volume hover:bg-accent-dark hover:shadow-accent-volume w-37 rounded-full bg-white py-3 px-8 text-center font-semibold transition-all hover:text-white"
              >
                Learn More
              </Link>
            </div>
          </div>

          {/* <!-- Hero image --> */}
          <div className="col-span-6 xl:col-span-8">
            <div className="relative text-center md:pl-8 md:text-right">
              <img
                src="/images/hero/hero.webp"
                alt=""
                className="hero-img mt-8 inline-block w-72 rotate-[8deg] sm:w-full lg:w-[24rem] xl:w-[35rem]"
              />
              <img
                src="/images/hero/3D_elements.png"
                alt=""
                className="animate-fly absolute top-0 md:-right-[10%]"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default hero;
