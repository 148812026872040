import React from "react";
import { Hero } from "../../components/component";
import Meta from "../../components/Meta";

const Home = ({ content }) => {
  return (
    <main>
      <Meta title={content?.metadataTitle} />
      <Hero content={content} />
    </main>
  );
};

export default Home;
