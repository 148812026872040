const newsLetterData = [
  {
    id: "1",
    icon: {
      parentBg: "#beaaf7",
      childBg: "rgb(131 88 255) ",
      svg: "icon-wallet",
    },

    title: ["Contact Us"],
    text: "Once you've contacted Blue Quanta, a representative will be in contact to begin exploring your opportunities.",
  },
  {
    id: "2",
    icon: {
      parentBg: "#c4f2e3",
      childBg: "rgb(16 185 129)",
      svg: "icon-wallet",
    },

    title: ["Get Your Plan"],
    text: "Our consultants will help develop a plan of attack for your technological journey, with speed to market, and technical excellence top of mind.",
  },
  {
    id: "3",
    icon: {
      parentBg: "#cddffb",
      childBg: "rgb(66 138 248)",
      svg: "icon-gallery",
    },
    title: ["Execute"],
    text: "Our team of expert engineers will create a custom tailored solution designed specifically to the needs  of your business.",
  },
  {
    id: "4",
    icon: { parentBg: "#ffd0d0", childBg: "rgb(239 68 68)", svg: "icon-list" },
    title: ["Test, Iterate, and Improve"],
    text: "Success requires experimentation and iteration.  There's no mountain that can't be climbed one step at a time.  We'll get you to the top!",
  },
];

export { newsLetterData };
