const auctions_category_data = [
  {
    id: "0Monkeyme#155",
    bigImage: "/images/products/item_8.jpg",
    creatorImage: "/images/avatars/creator_1.png",
    ownerImage: "/images/avatars/owner_1.png",
    price: "1.3 ETH",
    title: "Monkeyme#155",
    likes: "25",
    auction_timer: "000",
  },
  {
    id: "1Splendid Girl",
    bigImage: "/images/products/item_6.jpg",
    creatorImage: "/images/avatars/creator_4.png",
    ownerImage: "/images/avatars/owner_4.png",
    price: "10 ETH",
    title: "Splendid Girl",
    likes: "60",
    auction_timer: "000",
  },
  {
    id: "2Asuna #1649",
    bigImage: "/images/products/item_11.jpg",
    creatorImage: "/images/avatars/creator_8.png",
    ownerImage: "/images/avatars/owner_5.png",
    price: "0.8 ETH",
    title: "Asuna #1649",
    likes: "34",
    auction_timer: "000",
  },
  {
    id: "3Artof Eve",
    bigImage: "/images/products/item_10.jpg",
    creatorImage: "/images/avatars/creator_2.png",
    ownerImage: "/images/avatars/owner_7.png",
    price: "1.75 ETH",
    title: "Artof Eve",
    likes: "15",
    auction_timer: "550000000",
  },
  {
    id: "4Azuki #4017",
    bigImage: "/images/products/item_17.jpg",
    creatorImage: "/images/avatars/creator_2.png",
    ownerImage: "/images/avatars/owner_2.png",
    price: "9.3712 ETH",
    title: "Azuki #4017",
    likes: "334",
    auction_timer: "000",
  },
  {
    id: "5Block #2788",
    bigImage: "/images/products/item_18.jpg",
    creatorImage: "/images/avatars/creator_9.png",
    ownerImage: "/images/avatars/owner_8.png",
    price: "7.3 ETH",
    title: "Block #2788",
    likes: "52",
    auction_timer: "560000000",
  },
  {
    id: "6Crypto bull #6195",
    bigImage: "/images/products/item_19.jpg",
    creatorImage: "/images/avatars/creator_6.png",
    ownerImage: "/images/avatars/owner_4.png",
    price: "0.32 ETH",
    title: "Crypto bull #6195",
    likes: "90",
    auction_timer: "500000000",
  },
  {
    id: "7TSARÉVNA",
    bigImage: "/images/products/item_20.jpg",
    creatorImage: "/images/avatars/creator_3.png",
    ownerImage: "/images/avatars/owner_3.png",
    price: "4.7 ETH",
    title: "TSARÉVNA",
    likes: "260",
    auction_timer: "000",
  },
  {
    id: "8Monkeyme#155",
    bigImage: "/images/products/item_8.jpg",
    creatorImage: "/images/avatars/creator_1.png",
    ownerImage: "/images/avatars/owner_1.png",
    price: "1.3 ETH",
    title: "Monkeyme#155",
    likes: "25",
    auction_timer: "000",
  },
  {
    id: "9Splendid Girl",
    bigImage: "/images/products/item_6.jpg",
    creatorImage: "/images/avatars/creator_4.png",
    ownerImage: "/images/avatars/owner_4.png",
    price: "10 ETH",
    title: "Splendid Girl",
    likes: "60",
    auction_timer: "000",
  },
  {
    id: "10Asuna #1649",
    bigImage: "/images/products/item_11.jpg",
    creatorImage: "/images/avatars/creator_8.png",
    ownerImage: "/images/avatars/owner_5.png",
    price: "0.8 ETH",
    title: "Asuna #1649",
    likes: "34",
    auction_timer: "000",
  },
  {
    id: "11Artof Eve",
    bigImage: "/images/products/item_10.jpg",
    creatorImage: "/images/avatars/creator_2.png",
    ownerImage: "/images/avatars/owner_7.png",
    price: "1.75 ETH",
    title: "Artof Eve",
    likes: "15",
    auction_timer: "550000000",
  },
  {
    id: "12Azuki #4017",
    bigImage: "/images/products/item_17.jpg",
    creatorImage: "/images/avatars/creator_2.png",
    ownerImage: "/images/avatars/owner_2.png",
    price: "9.3712 ETH",
    title: "Azuki #4017",
    likes: "334",
    auction_timer: "000",
  },
  {
    id: "13Block #2788",
    bigImage: "/images/products/item_18.jpg",
    creatorImage: "/images/avatars/creator_9.png",
    ownerImage: "/images/avatars/owner_8.png",
    price: "7.3 ETH",
    title: "Block #2788",
    likes: "52",
    auction_timer: "560000000",
  },
  {
    id: "14Crypto bull #6195",
    bigImage: "/images/products/item_19.jpg",
    creatorImage: "/images/avatars/creator_6.png",
    ownerImage: "/images/avatars/owner_4.png",
    price: "0.32 ETH",
    title: "Crypto bull #6195",
    likes: "90",
    auction_timer: "500000000",
  },
  {
    id: "15TSARÉVNA",
    bigImage: "/images/products/item_20.jpg",
    creatorImage: "/images/avatars/creator_3.png",
    ownerImage: "/images/avatars/owner_3.png",
    price: "4.7 ETH",
    title: "TSARÉVNA",
    likes: "260",
    auction_timer: "000",
  },
];

export default auctions_category_data;
